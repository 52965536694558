import React from "react";
import Login from "./accounts/login";

export default function index() {
	return (
		<div>
			<Login />
		</div>
	);
}
